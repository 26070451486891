/* global document, window, console, fetch, Office */

const defaultReplyText = `Hey {{recipient}},
<br /><br />Thanks for reaching out. Submit your sales pitch using my <a href='{{bookingUrl}}'>Pitchfire link</a> and I'll review as soon as I can. 
<br /><br />Pitchfire makes it easy for me to review quick sales pitches from you and determine whether I'm interested or not. In the event that I am interested, I'll be able to book on your calendar. If I'm not, I'll be sure to give you insight into why. 
<br /><br />Please respect this process going forward, and only prospect me through Pitchfire.
<br /><br />Thank you
<br /><br />--
`;

let _psthg;

Office.onReady(async (info) => {
  if (info.host === Office.HostType.Outlook) {
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, initiateSetup);
    document.getElementById('not-loaded').classList.remove('active');
    document.getElementById('app-body').classList.add('active');
    document.getElementById('replyButton').onclick = sendToPitchfire;
    document.getElementById('alerts-close').onclick = closeAlert;

    _psthg = (window.posthog = window.posthog || []);

    await initiateSetup();
  }
});

function selectPinnedMessage() {
  if (Office.context.mailbox.item != null) {
    const msgFrom = Office.context.mailbox.item.from;

    document.getElementById('recipient-name').textContent = msgFrom.displayName;
    document.getElementById('recipient-email').textContent = msgFrom.emailAddress;
    document.getElementById('subject').textContent = Office.context.mailbox.item.subject;

    closeAlert();
    document.getElementById('preview').classList.add('active');
  } else {
    document.getElementById('preview').classList.remove('active');
  }
}

function setAlert(content, type = 'success') {
  const alerts = document.getElementById('alerts');
  alerts.classList.add('active');
  alerts.classList.add(type);
  document.getElementById('alerts-body').textContent = content;
}

function closeAlert() {
  document.getElementById('alerts').classList.remove('active');
  document.getElementById('alerts').classList.remove('success');
  document.getElementById('alerts').classList.remove('warning');
  document.getElementById('alerts').classList.remove('error');
  document.getElementById('alerts-body').textContent = '';
}

function showSetupScreen() {
  const login = document.getElementById('login');
  login.classList.add('active');

  const continueSetup = document.getElementById('continueSetup');
  continueSetup.classList.remove('active');

  document.getElementById('closeSetupButton').onclick = hideSetupScreen;

  let elements = document.querySelectorAll('.loginBtn');
  for (let i = 0; i < elements.length; i++) {
    elements[i].onclick = () => {
      elements[i].click();

      login.classList.remove('active');
      continueSetup.classList.add('active');
    };
  }
  document.getElementById('setup').classList.add('active');
}

function checkSetup() {
  let userId = getFromLocalStorage('pitchfireUserId');
  let userType = getFromLocalStorage('pitchfireUserType');
  let onboarded = getFromLocalStorage('pitchfireOnboarded');
  return (onboarded && userId && userType);
}

async function initiateSetup() {
  let currentUserEmail = Office.context.mailbox.userProfile.emailAddress;

  const isSetup = checkSetup();
  if (isSetup) {
    selectPinnedMessage();

    if (_psthg) {
      let userId = getFromLocalStorage('pitchfireUserId');
      _psthg.identify(userId, { email: currentUserEmail });
    }

    return;
  }

  const data = {
    email: currentUserEmail,
    platform: 'outlook',
  };

  try {
    const request = await fetch(process.env.APP_URL + '/api/plugin/initialise', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      withCredentials: true,
      body: JSON.stringify(data),
    });

    const response = await request.json();
    if (request.status === 200) {
      setInLocalStorage('pitchfireUserId', response.data.userId);
      setInLocalStorage('pitchfireUserType', response.data.userType);
      setInLocalStorage('pitchfireOnboarded', true);

      showStartScreen();
    } else if (request.status === 404) {
      // setAlert('We were unable to automatically set up your account, please create an account at ' + process.env.APP_URL + ' to proceed', 'warning');

      showSetupScreen();
    } else {
      setAlert(response.status + ' There was an error processing your request, please contact support@pitchfire.com', 'error');
    }
  } catch (error) {
    setAlert('An unknown error occurred while processing your request, please contact support@pitchfire.com', 'error');
    showSetupScreen();
  }
}

async function hideSetupScreen() {
  await initiateSetup();
  document.getElementById('setup').classList.remove('active');
}

function showStartScreen() {
  document.getElementById('start').classList.add('active');
  document.getElementById('closeStartButton').onclick = hideStartScreen;
}

function hideStartScreen() {
  selectPinnedMessage();
  document.getElementById('start').classList.remove('active');
}

function displayReplyFormAsync(data) {
  let body = data.reply;

  if (body === '') {
    body = defaultReplyText;

    for (let key in data) {
      body = body.replace('{{' + key + '}}', data[key].trim());
    }
  }

  Office.context.mailbox.item.displayReplyFormAsync(body, (asyncResult) => {
    console.log(JSON.stringify(asyncResult));
  });
}

async function sendToPitchfire() {
  const msgFrom = Office.context.mailbox.item.from;
  const currentUserEmail = Office.context.mailbox.userProfile.emailAddress;
  const data = {
    email: currentUserEmail,
    recipientName: msgFrom.displayName,
    recipientEmail: msgFrom.emailAddress,
    recipientSubject: Office.context.mailbox.item.subject,
    platform: 'outlook',
  };

  let userId = getFromLocalStorage('pitchfireUserId');
  let _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', { email: currentUserEmail, id: userId }]);

  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      body: JSON.stringify(data),
    };

    const response = await fetch(process.env.APP_URL + '/api/plugin/submit-lead', requestOptions);
    const result = await response.json();

    let notificationMessage;
    let alertType = 'error';
    if (response.status == 200) {
      if (result.message == 'success') {
        notificationMessage = 'The sender was successfully forwarded to Pitchfire.';
        alertType = 'success';
        displayReplyFormAsync(result.data);
      } else {
        notificationMessage = result.message;
      }
    } else if (response.status == 404) {
      notificationMessage =
          'We were unable to find an account for ' +
          currentUserEmail +
          '. Please create an account at https://app.pitchfire.com';
      alertType = 'warning';
    } else {
      notificationMessage = 'Error sending request. ' + response.status + ': ' + result.message;
    }

    setAlert(notificationMessage, alertType);
  } catch (error) {
    setAlert('An unknown error occurred while processing your request, please contact support@pitchfire.com', 'error');
  }
}

function setInLocalStorage(key, value) {
  const myPartitionKey = Office.context.partitionKey;

  // Check if local storage is partitioned.
  // If so, use the partition to ensure the data is only accessible by your add-in.
  if (myPartitionKey) {
    localStorage.setItem(myPartitionKey + key, value);
  } else {
    localStorage.setItem(key, value);
  }
}

function getFromLocalStorage(key) {
  const myPartitionKey = Office.context.partitionKey;

  // Check if local storage is partitioned.
  if (myPartitionKey) {
    return localStorage.getItem(myPartitionKey + key);
  } else {
    return localStorage.getItem(key);
  }
}
